import { PAGE_ROLES, PAGE_URI_SEO_SUFFIX, PageId } from '../constants';

async function getComponentById(editorSDK, appToken, componentId: string) {
  return editorSDK.components.getById(appToken, {
    id: componentId,
  });
}

async function getRouter(editorSDK, appToken, pageRef) {
  return editorSDK.document.routers.getByPage(appToken, {
    pageRef,
  });
}
async function renameStaticPagePrefix(editorSDK, appToken, page) {
  return editorSDK.pages.data.update(appToken, {
    pageRef: { id: page.id, type: page.type },
    data: {
      pageUriSEO: `${page.pageUriSEO}${PAGE_URI_SEO_SUFFIX}`,
    },
  });
}
async function addRouterToPage(
  editorSDK,
  appToken,
  prefix,
  appDefinitionId,
  pageRef,
  pageRole,
) {
  const routerRef = await editorSDK.document.routers.add(
    appToken,
    { prefix, config: {} },
    { appDefinitionId },
  );

  return editorSDK.document.routers.pages.connect(appToken, {
    routerRef,
    pageRef,
    pageRoles: [pageRole],
  });
}

export async function addBookingsCalendarRoute(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookingCalendarPage = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
  );
  if (bookingCalendarPage) {
    const bookingsCalendarComponent = await getComponentById(
      editorSDK,
      appToken,
      bookingCalendarPage.id,
    );

    const routerData = await getRouter(
      editorSDK,
      appToken,
      bookingsCalendarComponent,
    );
    if (!routerData) {
      const routerPrefix = bookingCalendarPage.pageUriSEO;

      await renameStaticPagePrefix(editorSDK, appToken, bookingCalendarPage);

      await addRouterToPage(
        editorSDK,
        appToken,
        routerPrefix,
        bookingCalendarPage.managingAppDefId,
        bookingsCalendarComponent,
        PAGE_ROLES.BOOKING_CALENDAR,
      );
    }
  }
}
export async function addServicePageRoute(editorSDK, appToken, allSitePages) {
  const bookingServicePage = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
  if (bookingServicePage) {
    const bookingServicePageComponent = await getComponentById(
      editorSDK,
      appToken,
      bookingServicePage.id,
    );

    const routerData = await getRouter(
      editorSDK,
      appToken,
      bookingServicePageComponent,
    );

    if (!routerData) {
      const routerPrefix = bookingServicePage.pageUriSEO;

      await renameStaticPagePrefix(editorSDK, appToken, bookingServicePage);

      await addRouterToPage(
        editorSDK,
        appToken,
        routerPrefix,
        bookingServicePage.managingAppDefId,
        bookingServicePageComponent,
        PAGE_ROLES.SERVICE_PAGE,
      );
    }
  }
}
